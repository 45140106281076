import React from 'react'
import Navbar from '../components/Layout/Navbar'
import MainBanner from '../components/Fund/MainBanner'
import Investment from '../components/Fund/Investment'
import Potential from '../components/Fund/Potential'
import Debts from '../components/Fund/Debts'
import Process from '../components/Home/Process'
import Location from '../components/Fund/Location'
import Selection from '../components/Fund/Selection'
import CTA from '../components/Home/CTA'
import Subscribe from '../components/Home/Subscribe'
import Footer from '../components/Home/Footer'


const Fund = () => {
  return (
    <div>
      <Navbar />
      <MainBanner />
      <Investment />
      <Potential />
      <Debts />
      <Process />
      <Location />
      <Selection />
      <CTA />
        <Subscribe />
        <Footer />
    </div>
  )
}

export default Fund