import React from 'react'
import Origination from "../../asset/2.png"
import Valuation from "../../asset/3.png"
import Due from "../../asset/5.png"
import Negotiation from "../../asset/6.png"
const Process = () => {
  return (
    <div className="xl:px-28 lg:px-12 py-6 px-6 lg:py-28">
        <div>
            <h4 className="md:text-[1.3rem] text-[1rem] font-[400] font-[GilroyMedium] text-[#034955] my-2">Our investment Process</h4>
            <p className="lg:text-[2rem] hidden md:block font-[600] font-[GilroyBold] text-[#034955] my-2" >Our rigorous due diligence process is <br /> extremely selective</p>
             <p className="text-[1.5rem] md:hidden block font-[600] font-[GilroyBold] text-[#034955] my-2" >Our rigorous due diligence process is  extremely selective</p>
            <p className='text-[.9rem] md:text-[1rem] font-[GilroyMedium]' >All our investment opportunities go through a 4-step process, carried out <br /> by our in-house Investment Professionals in collaboration with leading <br /> international advisors.</p>
            <p className='font-[GilroyMedium] text-[.9rem] md:text-[1rem]'>We screen hundreds of investments: less than 3% meet our standards.</p>
        </div>
        <div className='flex mt-12 items-center gap-6 flex-wrap justify-start' >
            <div className='bg-[#03495528] pt-4 px-3 md:px-8  rounded-xl items-start justify-between grid grid-cols-3 grid-flow-col w-full  lg:w-[450px] xl:w-[550px] 2xl:w-[600px]' > 
                <div className='col-span-2 pl-3 mt-3 md:mt-4 ' >
                    <h4 className='md:text-[1.8rem] text-[1rem] md:my-2 font-[GilroyBold] font-[600]' >Origination</h4>
                    <p className='font-[GilroyMedium]  xl:text-[.9rem] lg:text-[.8rem]  2xl:text-[1rem] text-[.5rem] '>We partner with the best RE developers, equity investors and institutional asset managers</p>
                </div>
                <div>
                    <img src={Origination} alt="" />
                </div>
            </div>
               <div className='bg-[#03495528] pt-4 px-3 md:px-8  rounded-xl items-start justify-between grid grid-cols-3 grid-flow-col w-full lg:w-[450px] xl:w-[550px] 2xl:w-[600px]' > 
                <div className='col-span-2 pl-3 mt-3 md:mt-4 ' >
                    <h4 className='md:text-[1.8rem] text-[1rem] md:my-2 font-[GilroyBold] font-[600]' >Deal Valuation</h4>
                    <p className='font-[GilroyMedium]  xl:text-[.9rem] lg:text-[.8rem]  2xl:text-[1rem] text-[.5rem]'>We analyze the Sponsors and the Project economic and financial soundness</p>
                </div>
                <div>
                    <img src={Valuation} alt="" />
                </div>
            </div>
             <div className='bg-[#03495528] pt-4 px-3 md:px-8  rounded-xl items-start justify-between grid grid-cols-3 grid-flow-col w-full lg:w-[450px] xl:w-[550px] 2xl:w-[600px]' > 
                <div className='col-span-2 pl-3 mt-0 md:mt-4 ' >
                    <h4 className='md:text-[1.8rem] text-[1rem] md:my-2 font-[GilroyBold] font-[600]' >Due Diligence</h4>
                    <p className='font-[GilroyMedium]  xl:text-[.9rem] lg:text-[.8rem]  2xl:text-[1rem] text-[.5rem]'>We perform extensive due diligence, involving external independent advisors and valuators</p>
                </div>
                <div>
                    <img src={Due} alt="" />
                </div>
            </div>
             <div className='bg-[#03495528] pt-4 px-3 md:px-8  rounded-xl items-start justify-between grid grid-cols-3 grid-flow-col w-full lg:w-[450px] xl:w-[550px] 2xl:w-[600px]' > 
                <div className='col-span-2 pl-3 mt-0 md:mt-4' >
                    <h4 className='md:text-[1.8rem] text-[1rem] md:my-2 font-[GilroyBold] font-[600]' >Final Negotiation</h4>
                    <p className='font-[GilroyMedium] xl:text-[.9rem] lg:text-[.8rem]  2xl:text-[1rem] text-[.5rem]'>We negotiate the best possible terms for our investors, structuring financial agreements to protect investors’ capital</p>
                </div>
                <div>
                    <img src={Negotiation} alt="" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Process