import React, { useEffect, useState } from 'react'
import Navbar from '../components/Layout/Navbar'
import DetailBanner from '../components/Deals/DetailBanner'
import BelowBanner from '../components/Deals/BelowBanner'
import Opportunity from '../components/Deals/Opportunity'
import ProductOverview from '../components/Deals/ProductOverview'
import PropertyDetail from '../components/Deals/PropertyDetail'
import TenantDetails from '../components/Deals/TenantDetails'
import PropertyLocation from '../components/Deals/PropertyLocation'
import AssetLayout from '../components/Deals/AssetLayout'
import Whoarewe from '../components/Deals/Whoarewe'
import Advantages from '../components/Deals/Advantages'
import PropertyImage from '../components/Deals/PropertyImage'
import CTA from "../components/Home/CTA"
import Subscribe from "../components/Home/Subscribe"
import Footer from "../components/Home/Footer"
import { useParams } from 'react-router-dom'
import { client } from '../client'
import {propertyDetailQuery} from "../components/utils/data"
import ImageGallery from '../components/Deals/ImageGallery'
const DealDetails = () => {
   const {id} = useParams()
   console.log(id)
  const [property, setProperty] = useState({})
  console.log(propertyDetailQuery(id))
  useEffect(() => {
    const query = propertyDetailQuery(id)
  
    client.fetch(query).then((data) => {
       setProperty(data[0])
      });
  }, [id])
 
  return (
    <div>
        <Navbar />
      {property?.bannerImage &&  <DetailBanner property={property}  />}
        <BelowBanner property={property} />
        <Opportunity property={property} />
        <ProductOverview property={property} />
        <PropertyDetail property={property} />
        <TenantDetails property={property} />
        <PropertyLocation property={property} />
        <AssetLayout property={property} />
        <ImageGallery property={property} />
        <Whoarewe  />
      <CTA />
      <Subscribe />
      <Footer />
    </div>
  )
}

export default DealDetails