import React from 'react'
import { urlFor } from '../../client'
import { MapPinIcon } from '@heroicons/react/24/outline';
import { Progress } from "@material-tailwind/react";
const PropertyCard = ({property}) => {
  return (
    <div>
         <div className='bg-white mt-4 transform transition duration-[500s] ease-in-out hover:mt-[-10px]  border-[1px] border-gray-200 shadow-lg rounded-lg' >
       {property?.image &&   <img className='rounded-t-lg' src={urlFor(property?.image)} />}
          <div className='px-4 py-4' >
          <h5 className='text-[1.5rem] font-[GilroyMedium]' >{property?.title}</h5>
          <p className='flex items-center font-[GilroyMedium] justify-start gap-2 my-2' ><MapPinIcon className='w-[1.5rem] text-[#32bba4]' /> Pune, India</p>
          <div className='mt-8' >
            <div className='flex my-2 bg-gradient-to-r from-teal-500 py-2 px-4 rounded-lg to-[#ffffffa3] items-center justify-between' >
              <p className='text-[.8rem] font-[GilroyRegular]' >Asset Value</p>
              <p className='text-[.8rem] font-[GilroyRegular]'>{property?.assetValue}</p>
            </div>
            <div className='flex my-2 bg-gradient-to-r from-teal-500 py-2 px-4 rounded-lg to-[#ffffffa3] items-center justify-between' >
              <p className='text-[.8rem] font-[GilroyRegular]' >IRR</p>
              <p className='text-[.8rem] font-[GilroyRegular]'>1{property?.irr}</p>
            </div>
            <div className='flex my-2 bg-gradient-to-r from-teal-500 py-2 px-4 rounded-lg to-[#ffffffa3] items-center justify-between' >
              <p className='text-[.8rem] font-[GilroyRegular]' >Asset Rental Yield</p>
              <p className='text-[.8rem] font-[GilroyRegular]'>{property?.yield}</p>
            </div>
          </div>
          <Progress className="my-8   " size="lg" color="teal" value={property?.percentage} label="Funded" />
          <button className='w-full bg-teal-500 text-white py-2 rounded-lg' >Invest Now</button>
          </div>
        </div>
    </div>
  )
}

export default PropertyCard