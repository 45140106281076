import React from 'react'
import Navbar from '../components/Layout/Navbar'
import MainBanner from '../components/HowItWorks/MainBanner'
import AssetClasses from '../components/HowItWorks/AssetClasses'
import Process from '../components/Home/Process'
import Experience from '../components/HowItWorks/Experience'
import CTA from '../components/Home/CTA'
import Subscribe from '../components/Home/Subscribe'
import Footer from '../components/Home/Footer'

const HowitWorks = () => {
  return (
    <div>
      <Navbar />
      <MainBanner />
      <AssetClasses />
      <Process />
      <Experience />
      <CTA />
        <Subscribe />
        <Footer />
    </div>
  )
}

export default HowitWorks