import React from 'react'

import "./Works.css"
const MainBanner = () => {
  return (
    <div  className='worksBanner  mt-[-120px] lg:mt-[-130px]' >
        <div className='lg:pt-[220px] pt-[170px] px-8 lg:w-[900px] lg:px-24 flex-col flex items-start justify-start ' >
            <h4 className='lg:text-[3rem] text-[1.8rem] font-[GilroyBold] text-left text-black font-[600]' >Uncover Fresh <span className='text-[#FE6539]' >Investment</span>  Prospects</h4>
            <p className='text-black lg:text-[1rem] text-[.8rem] font-[GilroyMedium] font-[400] mt-3  ' >Access Exclusive Commercial Real Estate Investment <br/> Opportunities through Propstreet.  Our private marketplace links <br/> certified brokers with qualified investors to provide enhanced <br/> access to lucrative deals, valuable market insights, and global v <br/> investment opportunities.</p>
            <button className='rounded-full font-[GilroyBold] text-[.9rem] mt-6 lg:mt-10 py-3 px-12 bg-white font-[600]' >Subscribe to get Deal Updates</button>
        </div>
    </div>
  )
}

export default MainBanner