import React from 'react'
import { urlFor } from '../../client'

const ImageGallery = ({property}) => {
  return (
    <div className='lg:py-12 py-8 mx-8  lg:mx-36  '>
         <h4 className='font-[GilroyBold] text-[2rem] lg:text-[2.5rem]' >Image <span className='text-[#FE6539]' > Gallery</span></h4>
         <div className='flex items-center justify-start my-8 flex-wrap gap-12' >
            {
             property &&   property?.imageGallery?.map((item)=>(
                    <div>
                        <img className='w-[450px]' src={urlFor(item)} alt="" />
                    </div>
                ))
            }
         </div>
    </div>
  )
}

export default ImageGallery