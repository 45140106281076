import React from 'react'

const Whoarewe = () => {
  return (
    <div className='lg:py-12 mx-8 py-8  lg:mx-36'>
         <h4 className='font-[GilroyBold] text-[2rem] lg:text-[2.5rem]' >Who <span className='text-[#FE6539]' > we are</span></h4>
         <div className='flex items-center gap-4 lg:gap-12 mt-8 flex-wrap justify-center' >
            <div className='bg-[#03495549] py-12 px-8 w-[350px] text-center rounded-lg' >
                <p className='font-[GilroyBold] text-[2rem] text-[#034955] ' >300+</p>
                <p className='font-[GilroyMedium] text-[1.2rem]' >Crores AUM</p>
            </div>
             <div className='bg-[#03495549] py-12 px-8 w-[350px] text-center rounded-lg' >
                <p className='font-[GilroyBold] text-[2rem] text-[#034955] ' >~14-20%</p>
                <p className='font-[GilroyMedium] text-[1.2rem]' >IRR</p>
            </div>
             <div className='bg-[#03495549] py-12 px-8 w-[350px] text-center rounded-lg' >
                <p className='font-[GilroyBold] text-[2rem] text-[#034955] ' >Product Exits</p>
                <p className='font-[GilroyMedium] text-[1.2rem]' >First in the Industry</p>
            </div>
             <div className='bg-[#03495549] py-12 px-8 w-[350px] text-center rounded-lg' >
                <p className='font-[GilroyBold] text-[2rem] text-[#034955] ' >30+ Investors</p>
                <p className='font-[GilroyMedium] text-[1.2rem]' >Geographies</p>
            </div>
             <div className='bg-[#03495549] py-12 px-8 w-[350px] text-center rounded-lg' >
                <p className='font-[GilroyBold] text-[2rem] text-[#034955] ' >100%</p>
                <p className='font-[GilroyMedium] text-[1.2rem]' >On time Payment</p>
            </div>
         </div> 
    </div>
  )
}

export default Whoarewe