import { Route, Routes, useParams } from 'react-router-dom';
import './App.css';
import Home,{useState,useEffect} from './pages/Home';
import HowitWorks from './pages/HowitWorks';
import Fund from './pages/Fund';
import Deals from './pages/Deals';
import DealDetails from './pages/DealDetails';

function App() {
 
  return (
    <div className="App">
      <Routes >
        <Route exact path="/" element={<Home/>}   />
        <Route exact path="/howitworks" element={<HowitWorks />}   />
         <Route exact path="/fund" element={<Fund />}   />
          <Route exact path="/deals" element={<Deals />}   />
           <Route exact path="/dealDetails/:id" element={<DealDetails /> }   />
      </Routes>
    </div>
  );
}

export default App;
