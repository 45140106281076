import React from 'react'
import CTAImage from "../../asset/ctaImage.png"
const CTA = () => {
  return (
    <div className='bg-[#242527] py-6 lg:pt-3 lg:pb-0 px-6 md:px-28 md:flex items-center gap-16 justify-end' >
        <div className='flex items-center justify-center flex-col' >
            <h4 className='text-center font-[GilroyBold]  my-1 text-[1.3rem] md:text-[2rem] text-white ' >Are you a Real Estate developer?</h4>
            <p className='text-center text-[1rem] md:text-[1.2rem] font-[GilroyMedium] font-[400] my-1 text-gray-400 '>Discover how Reon Capital can help you raise funds, and support your business</p>
            <button className='bg-white font-[GilroyBold] lg:text-[1rem] text-[.9rem] rounded-full  py-3 mt-4 px-8 ' >Submit Your Work</button>
        </div>
        <div className='hidden lg:block ml-48' >
            <img src={CTAImage} alt="" />
        </div>
    </div>
  )
}

export default CTA