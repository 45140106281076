import React, { useEffect, useState } from 'react'
import Navbar from '../components/Layout/Navbar'
import HomeBanner from '../components/Home/HomeBanner'
import BelowBanner from '../components/Home/BelowBanner'
import WhyReon from '../components/Home/WhyReon'
import Process from '../components/Home/Process'
import CTA from '../components/Home/CTA'
import Subscribe from '../components/Home/Subscribe'
import Footer from '../components/Home/Footer'
import BelowBannerCarousel from '../components/Home/BelowBannerCarousel'
import { client } from '../client'
import BannerCta from '../components/Home/BannerCta'
import Strategy from '../components/Home/Strategy'
import Experience from '../components/HowItWorks/Experience'
import Offering from '../components/Home/Offering'

const Home = () => {
   const [home, setHome] = useState([])
  
   useEffect(()=>{
        const query = '*[_type == "homeContent"] | order(_updatedAt desc)'
             client.fetch(query).then((data) => {
       setHome(data[0])
      });
  
    },[])
    console.log(home)
  return (
    <div>
        <Navbar />
        <HomeBanner home={home} />
        {/* <BannerCta /> */}
        {/* <Strategy /> */}
        <BelowBannerCarousel home={home} />
        <Offering home={home} />
        <WhyReon />
        <Experience />
      
        <CTA />
        <Subscribe />
        <Footer />
    </div>
  )
}

export default Home