import React from 'react'
import { urlFor } from '../../client'
import "./Deal.css"
const DetailBanner = ({property}) => {
 
  return (
    <>
   {property && <div style={{backgroundImage:`url(${urlFor(property && property?.bannerImage)})`}}  className='detailsBanner mt-[-120px] lg:mt-[-130px]' >
        <div className='lg:pt-[220px] pt-[170px] px-8 lg:w-[900px] md:w-[600px] lg:px-24 flex-col flex items-start justify-start  ' >
            <h4 className='lg:text-[3rem] text-[1.8rem] font-[GilroyBold] text-left text-white font-[600]' > {property?.name}</h4>
            <p className='text-white lg:text-[1rem] text-[.8rem] font-[GilroyMedium] font-[400] mt-3 ' >
              {property?.description}
            </p>
            <button className='rounded-full font-[GilroyBold] text-[.9rem] mt-6 lg:mt-10 py-3 px-12 bg-white font-[600]' >Apply Pack</button>
        </div>
    </div>}
    </>
  )
}

export default DetailBanner