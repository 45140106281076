import React from 'react'
import AssetClass from "../../asset/selection.png"
const Selection = () => {
  return (
    <div className='lg:grid grid-cols-2 grid-flow-col border-gray-200 border-b-[1px] gap-12 items-center lg:mx-24 py-12 mx-8 lg:py-24' >
        <div>
            <h4 className='font-[GilroyMedium] text-[#FE6539] text-[1.2rem] ' >Project Selection</h4>
            <h5 className='font-[GilroyBold] text-black text-[1.5rem] lg:text-[2.5rem] lg:leading-[2.8rem]' >Prime residential and <br /> hospitality projects</h5>
            <p className='font-[GilroyMedium] text-[.8rem] lg:text-[1.1rem] my-2 lg:leading-[1.9rem] ' >Our investment strategy focuses on high-end, luxury <br /> residential and hospitality projects, which can prove <br /> resilient in turbulent times and opportunistic positions</p>
        </div>
        <div className='flex items-center justify-end' >
            <img className='w-[450px]' src={AssetClass} alt="" />
        </div>
    </div>
  )
}

export default Selection