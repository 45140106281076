import React from 'react'
import HomeBannerImage from "../../asset/mainVideo.webm"
import HomeBannerMobile from "../../asset/mainVideoMob.webm"
import "./Home.css"
const HomeBanner = ({home}) => {
  return (
    <div className='relative  mt-[-120px] lg:mt-[-130px]' >
    <div className=' overflow-hidden'>
        <video className=' z-[-1] lg:block hidden  h-[80vh]  lg:h-auto w-full' playsInline={true}   loop="true" autoplay="autoplay" muted>
            <source src={HomeBannerImage} type="video/mp4" />
        </video>
        <video className=' z-[-1] block lg:hidden   lg:h-auto w-full' playsInline={true}   loop="true" autoplay="autoplay" muted>
            <source src={HomeBannerMobile} type="video/mp4" />
        </video>
        </div>
       
        <div className='absolute  bottom-0 flex items-start justify-center flex-col lg:pt-[220px] px-4 pt-[100px] md:w-[500px] lg:w-[900px] lg:px-24  m-auto w-full z-[3] top-[10px] lg:top-[-205px]'>
        <h4 className='lg:text-[2.5rem] text-[1.7rem] font-[GilroyBold] text-left text-white font-[600]' >{home?.bannerTitle}</h4>
            <p className='text-gray-200 font-[GilroyRegular]  text-[.8rem] lg:text-[1rem] font-[400] mt-3  '>
            {home?.bannerDescription}
            


            </p>
            
            <button className='rounded-full font-[GilroyBold] text-[.8rem] lg:text-[1rem] mt-10 py-3 px-12 bg-white font-[600]' >Book Now</button>
            </div>
  
    </div>
    
  )
}

export default HomeBanner