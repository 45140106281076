export const propertyDetailQuery = (id) => {
  const query = `*[_type == "deals" && _id == '${id}']{
    name,
    _id,
    tagline, 
    description,
    startingYield,
    tenure,
    fundedPercentage,
    bannerImage,
    locationName,
    detailedContent,
    propertyDetails,
    imageGallery,
    tenantDetails,
    productOverview,
    propertyLocation,
    assetLayout,
    investmentType,
    assetValue,
    iRR,
    rentalYield,
    multiplier
   
  }`;
  return query;
};