import React, { useEffect, useState } from 'react'
import Navbar from '../components/Layout/Navbar'
import DealsPack from '../components/Deals/DealsPack'
import CTA from '../components/Home/CTA'
import Subscribe from '../components/Home/Subscribe'
import Footer from '../components/Home/Footer'
import { client } from '../client'

const Deals = () => {
  const [properties, setProperties] = useState([])
  
   useEffect(()=>{
        const query = '*[_type == "deals"] | order(_updatedAt desc)'
             client.fetch(query).then((data) => {
       setProperties(data)
      });
  
    },[])
    console.log(properties)
  return (
    <div>
        <Navbar background="#000" />
        <DealsPack properties={properties} />
        <CTA />
        <Subscribe />
        <Footer />
    </div>
  )
}

export default Deals