import React from 'react'

const Potential = () => {
  return (
    <div className='flex items-center lg:px-[160px] px-8 py-28 bg-[#034955] justify-center flex-col' >
        <div>
            <h4 className='font-[GilroyBold] text-[2rem] lg:text-[3.5rem] text-center text-white' >Unlock Infinite <br /> Investment Potentia</h4>
        </div>
        <div className='border-b-[1px] mt-6 py-12 lg:w-[500px] border-gray-200' >
            <h4 className='font-[GilroyMedium] text-[1.5rem] lg:text-[2rem] text-center text-gray-300' >Redemptions</h4>
            <p className='font-[GilroyBold] text-[2.5rem] lg:text-[3.5rem] text-center text-white'>Every 6 months</p>
        </div>
         <div className='border-b-[1px] mt-6 py-12 lg:w-[500px] border-gray-200' >
            <h4 className='font-[GilroyMedium] text-[1.5rem] lg:text-[2rem] text-center text-gray-300' >Duration</h4>
            <p className='font-[GilroyBold] text-[2.5rem] lg:text-[3.5rem] text-center text-white'>Open Ended</p>
        </div>
         <div className='border-b-[1px] mt-6 py-12 lg:w-[500px] border-gray-200' >
            <h4 className='font-[GilroyMedium] text-[1.5rem] lg:text-[2rem] text-center text-gray-300' >Target Fund Size</h4>
            <p className='font-[GilroyBold] text-[2.5rem] lg:text-[3.5rem] text-center text-white'>EUR 100m</p>
        </div>
    </div>
  )
}

export default Potential