import React from 'react'

const Subscribe = () => {
  return (
    <div className='bg-[#3A3B3E] lg:py-20 lg:px-36 flex-col lg:flex-row px-8 py-8 flex items-center justify-between ' >
        <div>
            <h4 className='text-white font-[GilroyBold] my-2 text-[1.5rem] lg:text-[2rem]' >Get in touch with us</h4>
            <p className='text-white font-[GilroyRegular] text-[.7rem] lg:text-[.9rem] ' >Subscribe to RILD Newsletter, receive updates and insights <br /> on Real Estate and stay updated on your investment opportunity</p>
        </div>
        <div>
        <div className='relative  mt-3 w-full lg:w-[400px]' >
            <input type="text" className='py-4 rounded-full   px-6 w-full md:w-[350px]' placeholder='mail@gmail.com' />
            <button className='bg-black absolute rounded-full text-white right-[.6rem] md:right-[3.5rem] top-1 py-3 px-4' >Subscribe</button>
           
        </div>
        <p className='text-gray-200 mt-3  font-[GilroyRegular] md:w-[450px]'>By Continuing, I give my concern to the processing of my personal data</p>
        </div>
    </div>
  )
}

export default Subscribe