import React from 'react'
import AssetClass from "../../asset/location.png"
const Location = () => {
  return (
    <div className='lg:grid grid-cols-2 grid-flow-col border-gray-200 border-b-[1px] gap-12 items-center mx-8 py-12 lg:mx-24 lg:py-24' >
        <div>
            <h4 className='font-[GilroyMedium] text-[#FE6539] text-[1.2rem] ' >Asset classes</h4>
            <h5 className='font-[GilroyBold] text-black text-[1.5rem] lg:text-[2rem] leading-[2.4rem]' >Key target geographies</h5>
            <p className='font-[GilroyMedium] text-[.8rem] lg:text-[1.1rem] my-2 lg:leading-[1.9rem] ' >Our investment focus lies in Maharashtra, Bihar, <br /> Uttarakhand and Many more, geographies where we <br /> have a solid local presence and strong ties with real <br /> estate developers, equity investors and institutional <br /> asset managers.</p>
        </div>
        <div className='flex items-center justify-end' >
            <img className='w-[450px]' src={AssetClass} alt="" />
        </div>
    </div>
  )
}

export default Location