import React from 'react'
import AssetClass from "../../asset/debts.png"
const Debts = () => {
  return (
       <div className='lg:grid grid-cols-2 grid-flow-col border-gray-200 border-b-[1px] gap-12 items-center mx-8 py-12 lg:mx-24 lg:py-24' >
        <div>
            <h4 className='font-[GilroyMedium] text-[#FE6539] text-[1.2rem] ' >Why real estate private debt</h4>
            <h5 className='font-[GilroyBold] text-black text-[1.5rem] lg:text-[2rem] leading-[2.4rem]' >A compelling investment <br className='hidden lg:block' /> opportunity</h5>
            <p className='font-[GilroyMedium] text-[.8rem] lg:text-[1.1rem] my-2 lg:leading-[1.9rem] ' >Global private debt is growing steadily, with Real Estate accounting for <br className='hidden lg:block' /> over 15% of the market as banks facing tighter equity standards are <br className='hidden lg:block' /> drastically reducing lending facilities.</p>
            <p className='font-[GilroyMedium] text-[.8rem] lg:text-[1.1rem] my-2 lg:leading-[1.9rem] '>Shortfall of credit supply creates compelling set of risk-adjusted <br className='hidden lg:block' /> investment opportunities and increased interest for alternative credit <br className='hidden lg:block' /> solutions</p>
        </div>
        <div className='flex items-center justify-end' >
            <img className='w-[450px]' src={AssetClass} alt="" />
        </div>
    </div>
  )
}

export default Debts