import React from 'react'

const Opportunity = ({property}) => {
  return (
    <div className='lg:py-12 py-8 mx-8 border-b-[1px] border-gray-300  lg:mx-36' >
        <div>
            <h4 className='font-[GilroyMedium] text-[#FE6539] text-[.9rem] lg:text-[1.2rem]' >TIDEL PARK</h4>
            <p className='font-[GilroyBold] text-[#035554] text-[1.5rem] lg:text-[2rem]'>Investment Opportunity</p>
        </div>
        <div className='flex my-12 gap-6 flex-wrap items-center justify-between' >
            <div className='w-[350px] rounded-lg text-center bg-[#0355543f] py-16 px-12' >
                <h4 className='text-[1.5rem] font-[GilroyRegular] ' >Investment type</h4>
                <p className='font-[GilroyBold] text-[1.2rem] text-[#034955] ' >{property?.investmentType}</p>
            </div>  
              <div className='w-[350px] rounded-lg text-center bg-[#0355543f] py-16 px-12' >
                <h4 className='text-[1.5rem] font-[GilroyRegular] ' >Asset value</h4>
                <p className='font-[GilroyBold] text-[1.2rem] text-[#034955] ' >{property?.assetValue}</p>
            </div>  
              <div className='w-[350px] rounded-lg text-center bg-[#0355543f] py-16 px-12' >
                <h4 className='text-[1.5rem] font-[GilroyRegular] ' >Expected IRR</h4>
                <p className='font-[GilroyBold] text-[1.2rem] text-[#034955] ' >{property?.iRR}</p>
            </div>  
              <div className='w-[350px] rounded-lg text-center bg-[#0355543f] py-16 px-12' >
                <h4 className='text-[1.5rem] font-[GilroyRegular] ' >Avg Rental Yield</h4>
                <p className='font-[GilroyBold] text-[1.2rem] text-[#034955] ' >{property?.rentalYield}</p>
            </div>  
              <div className='w-[350px] rounded-lg text-center bg-[#0355543f] py-16 px-12' >
                <h4 className='text-[1.5rem] font-[GilroyRegular] ' >Multiplier</h4>
                <p className='font-[GilroyBold] text-[1.2rem] text-[#034955] ' >{property?.multiplier} </p>
            </div>  
              <div className='w-[350px] rounded-lg text-center bg-[#0355543f] py-16 px-12' >
                <h4 className='text-[1.5rem] font-[GilroyRegular] ' >Expected Tenure</h4>
                <p className='font-[GilroyBold] text-[1.2rem] text-[#034955] ' >{property?.tenure}</p>
            </div>  
        </div>
    </div>
  )
}

export default Opportunity