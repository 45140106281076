import React from 'react'
import LogoMain from "../../asset/logoMain.png"
import {EnvelopeIcon,PhoneIcon} from "@heroicons/react/20/solid"
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <div className='bg-[#242527] py-6 px-6 lg:px-28  lg:grid grid-cols-4 grid-flow-col' >
        <div >
            <img className='lg:w-[150px] w-[120px] py-3 lg:py-0 ' src={LogoMain} alt="" />
        </div>
        <div>
            <h4 className='text-white text-[1.5rem] font-[GilroyBold]  ' >Sitemap</h4>
           <Link to="/" > <p className='text-gray-300 my-2 font-[GilroyMedium]' >Home</p></Link>
           <Link to="/howitworks" >  <p className='text-gray-300 my-2 font-[GilroyMedium]' >How it Works</p></Link>
            <p className='text-gray-300 my-2 font-[GilroyMedium]' >Investors</p>
            <p className='text-gray-300 my-2 font-[GilroyMedium]' >Broker</p>
        </div>
          <div>
            <h4 className='text-white text-[1.5rem] font-[GilroyBold] font-[500] ' >Important Links</h4>
            <p className='text-gray-300 my-2 font-[GilroyMedium]' >Terms and Conditions</p>
            <p className='text-gray-300 my-2 font-[GilroyMedium]' >Privacy Policy</p>
        </div>
         {/* <div>
            <h4 className='text-white text-[1.5rem] font-[GilroyBold] font-[500] ' >Contact Us</h4>
            <p className='text-gray-300 my-2 flex font-[GilroyMedium] items-center justify-start gap-3' ><EnvelopeIcon className='w-[20px]' />info@rild.com</p>
            <p className='text-gray-300 my-2 flex font-[GilroyMedium] items-center justify-start gap-3' ><PhoneIcon className='w-[20px]' />+91 111 111 1111</p>
        </div> */}
    </div>
  )
}

export default Footer