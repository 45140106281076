import React from 'react'
import Logo2 from "../../asset/logoMain.png"
import { MapPinIcon } from "@heroicons/react/24/outline";
import { Link } from 'react-router-dom';
import { Progress } from "@material-tailwind/react";
const DealsPack = ({properties}) => {
  return (
    <div className='px-8 py-24' >
        <h4 className='font-[GilroyBold] text-center text-[2.5rem]' >Discover Our Flexible Pricing Plans</h4>
        <div className='flex items-center my-12 justify-center gap-8 flex-wrap' >
        {
          properties?.map((property,index) =>(
             <div className='bg-[#242527] 2xl:w-[450px] lg:w-[380px] py-2 rounded-lg px-8' >
            <div className='border-b-[1px] border-gray-300 py-6' >
                <img  className='w-[80px] my-4' src={Logo2} alt="" />
                <p className='font-[GilroyBold] text-white text-[1.5rem]' >{property?.name}</p>
                <p className='font-[GilroyMedium] text-white text-[1rem]'>{property?.tagline}</p>
                </div>
                <div>
                     <p className="flex items-center font-[GilroyMedium] text-white mt-3 text-[1rem] justify-start gap-2">
            {" "}
            <MapPinIcon className="w-[20px]  " /> {property?.locationName} </p>
            <p className='text-gray-300 my-4 font-[GilroyMedium]' >{property?.description}</p>
            <Progress className="my-4  bg-white lg:w-[300px]" size="lg" color="blue" value={property?.fundedPercentage} label="Funded" />
            <div className='flex items-center justify-center py-4' >
         <Link to={`/dealDetails/${property?._id}`} >  <button className='rounded-full font-[GilroyBold] w-[250px] text-[.9rem] mt-10 py-3 px-12 bg-white font-[600]' >Apply Fund</button></Link> 
            </div>
                </div>
            </div>
          ))
        }
           
            
              
        </div>
    </div>
  )
}

export default DealsPack