import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import Logo from "../../asset/logoMain.png"
import {Link, NavLink} from "react-router-dom"
import Logo2 from "../../asset/logoMain.png"
import {
  ArrowPathIcon,
  Bars3Icon,
  BookmarkSquareIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  LifebuoyIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
  XMarkIcon,
  InformationCircleIcon,
  HomeIcon,
  UserGroupIcon,
  HomeModernIcon,
  BanknotesIcon,
  QuestionMarkCircleIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

const mobileNav = [
  {
    name: 'Home',
    description: 'Get a better understanding of where your traffic is coming from.',
    href: '/',
    icon: HomeIcon,
  },
  {
    name: 'How It Works',
    description: 'Speak directly to your customers in a more meaningful way.',
    href: '/howitworks',
    icon: QuestionMarkCircleIcon ,
  },
  
  // {
  //   name: 'Co-Living Fund',
  //   description: "Connect with third-party tools that you're already using.",
  //   href: '/fund',
  //   icon: InformationCircleIcon,
  // },
  // {
  //   name: 'Deals By Deals',
  //   description: "Connect with third-party tools that you're already using.",
  //   href: '/deals',
  //   icon: UserGroupIcon,
  // },
  //  {
  //   name: 'Broker',
  //   description: "Connect with third-party tools that you're already using.",
  //   href: '/broker',
  //   icon: CalendarIcon,
  // },
  
]
const callsToAction = [
  { name: 'Watch Demo', href: '#', icon: PlayIcon },
  { name: 'Contact Sales', href: '#', icon: PhoneIcon },
]
const resources = [
  {
    name: 'Deal by Deal',
    description: 'Get all of your questions answered in our forums or contact support.',
    href: '/deals',
    icon: LifebuoyIcon,
  },
   {
    name: 'Co-Living Fund',
    description: 'Get all of your questions answered in our forums or contact support.',
    href: '/fund',
    icon: BanknotesIcon,
  },

]
const recentPosts = [
  { id: 1, name: 'Boost your conversion rate', href: '#' },
  { id: 2, name: 'How to use search engine optimization to drive traffic to your site', href: '#' },
  { id: 3, name: 'Improve your customer experience', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar({background}) {
  console.log(background)
  return (
    <Popover style={{backgroundColor:background}} className={`relative bg-[${background}]  text-[#000] z-[999]`}>
      <div className="mx-0 max-w-full py-2 px-6">
        <div className="flex items-center justify-between lg:ml-12  py-3 md:justify-start md:space-x">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <span className="sr-only">Your Company</span>
              <img
                className="lg:w-[120px] w-[80px] "
                src={Logo2}
                alt=""
              />
            </Link>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
          
            <Popover.Button className="inline-flex items-center justify-center rounded-md  p-2 text-white hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-8 w-8" aria-hidden="true" />
            </Popover.Button>
          </div>
          
            
             
          <Popover.Group as="nav" className="hidden lg:flex items-center justify-end  lg:ml:0 md:ml-48 space-x-10 md:flex">
            {/* <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className='text-[1.1rem] flex items-center justify-center gap-1 mx-0 outline-none  tracking-widest font-[GilroyMedium]  text-[#ebe3d6] hover:text-gray-100'
                  >
                    <span>Investors</span>
                    <ChevronDownIcon
                      className='ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500'
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3  transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                      <div className="overflow-hidden  rounded-lg w-[250px] shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-6  bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {resources.map((item) => (
                            <NavLink
                              key={item.name}
                              to={item.href}
                              className="-m-3 flex items-start rounded-lg p-2 hover:bg-gray-50"
                            >
                              <item.icon className="h-6 w-6 flex-shrink-0 text-indigo-600" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                              </div>
                            </NavLink>
                          ))}
                        </div>
                       
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover> */}

            <Link to="/howitworks" className="text-[1.1rem] mx-4  tracking-widest font-[GilroyMedium]  text-[#ebe3d6] hover:text-gray-100">
              How it Works
            </Link>
{/*           
             <Link to="/community" className="text-lg ml-4  tracking-widest font-[GilroyMedium]  text-[#ebe3d6] hover:text-gray-100">
        Broker
            </Link> */}
            <Link
                 to="/form" 
                  className="flex  items-center font-[GilroyBold]  justify-center rounded-full border border-transparent bg-white  px-8 py-3 text-base font-medium text-black  shadow-sm "
                >
               Book Now
                </Link>

            {/* <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                    )}
                  >
                    <span>More</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-md -translate-x-1/2 transform px-2 sm:px-0">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {resources.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
                            >
                              <item.icon className="h-6 w-6 flex-shrink-0 text-indigo-600" aria-hidden="true" />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                              </div>
                            </a>
                          ))}
                        </div>
                        <div className="bg-gray-50 px-5 py-5 sm:px-8 sm:py-8">
                          <div>
                            <h3 className="text-base font-medium text-gray-500">Recent Posts</h3>
                            <ul role="list" className="mt-4 space-y-4">
                              {recentPosts.map((post) => (
                                <li key={post.id} className="truncate text-base">
                                  <a href={post.href} className="font-medium text-gray-900 hover:text-gray-700">
                                    {post.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className="mt-5 text-sm">
                            <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                              View all posts
                              <span aria-hidden="true"> &rarr;</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover> */}
          </Popover.Group>
         
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden">
          <div className="divide-y-2 divide-gray-50 rounded-lg bg-black  shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className=" w-[100px]"
                    src={Logo}
                    alt="Your Company"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md  p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6 " aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {mobileNav.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50"
                    >
                      <item.icon style={{
              background: "linear-gradient(to right, #ee0979 0%, #ff6a00 100%)",
              "-webkit-background-clip": "text",
              "-webkit-text-fill-color": "transparent",
            }} className="h-6 w-6 flex-shrink-0 " aria-hidden="true" />
                      <span className=" text-base text-white font-medium ">{item.name}</span>
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
            <div className="space-y-6 py-6 px-5">
              {/* <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                  Pricing
                </a>

                <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                  Docs
                </a>
                {resources.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    {item.name}
                  </a>
                ))}
              </div> */}
              <div>
                <Link
                 to="/form" 
                  className="flex w-full items-center justify-center border border-transparent bg-white  px-4 py-2 text-base font-medium text-black rounded-full shadow-sm "
                >
                Book Now
                </Link>
                {/* <p className="mt-6 text-center text-base font-medium text-gray-500">
                  Existing customer?{' '}
                  <a href="#" className="text-indigo-600 hover:text-indigo-500">
                    Sign in
                  </a>
                </p> */}
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}