import React from 'react'
import DetailOverview from "../../asset/detailOverview.png"
import PortableText from 'react-portable-text'
import { urlFor } from '../../client'
const ProductOverview = ({property}) => {
  return (
    <div className='lg:py-12 py-8 lg:grid grid-cols-2 items-center justify-between grid-flow-col px-8 lg:px-36'>
        <div className='col-span-1' >
            <h4 className='font-[GilroyBold] text-[2rem] lg:text-[2.5rem] text-[#034955] mb-4 lg:mb-12' >Product Overview</h4>
            <p className='font-[GilroyMedium] text-[.9rem] lg:text-[1.3rem]' >
           {property?.productOverview?.mainContent && <PortableText
      // Pass in block content straight from Sanity.io
      content={property?.productOverview?.mainContent}
      // Optionally override marks, decorators, blocks, etc. in a flat
      // structure without doing any gymnastics
      serializers={{
        h1: (props) => <h1 style={{"background":"linear-gradient(to right, #ee0979 0%, #ff6a00 100%)","-webkit-background-clip":"text","-webkit-text-fill-color":"transparent",fontFamily:"NHaasGroteskDSPro-75Bd",fontWeight:"bold",fontSize:"2rem", margin:"15px 0px",textAlign:"center"}} {...props} />,
        h3: (props) => <h3 style={{ color: "#3c3c3c",margin:"15px 0px",fontSize:"1.8rem",fontWeight:"600" }} {...props} />,
        li: ({ children }) => <li className="special-list-item">{children}</li>,
        normal: (props) => <normal style={{  }} {...props} />
      }}
    />}</p>
        </div>
        <div className='flex items-center justify-end' >
           {property?.productOverview && <img src={urlFor(property?.productOverview?.mainImage)} className='mt-6 lg:mt-0' alt="" />}
        </div>
    </div>
  )
}

export default ProductOverview