import React from 'react'
import Subscribe from "../../asset/subscribe.png"
import Discover from "../../asset/discover.png"
import Stay from "../../asset/staytuned.png"
import Monitor from "../../asset/monitor.png"
const Experience = () => {
  return (
    <div className='lg:mx-24 mx-6 py-6 lg:py-24 border-t-[1px] bordergray-200' >
        <div>
                <h3 className='font-[GilroyMedium] text-teal-500' >A digital experience</h3>
                <p className='font-[GilroyBold] text-[1.3rem] md:text-[2rem] md:leading-[2.5rem] text-black' >Sign up in a few minutes and  choose <br className='hidden lg:block' /> your investments</p>
        </div>
        <div className='md:grid grid-cols-2 gap-8 items-center justify-between my-6  lg:my-12 grid-flow-row ' >
            <div className='text-left my-4 md:my-0 col-span-1' >
                <img  src={Subscribe} alt="" />
                <h3 className='font-[GilroyBold]  ml-2 md:text-[1.5rem] text-[1.2rem] mt-2 text-teal-500'>Subscribe</h3>
                <p className='font-[GilroyMedium]  ml-2 md:text-[1.2rem] text-[.8rem] mt-1 text-black'>Sign up in a few minutes and activate your professional <br className='hidden lg:block' /> investor profile</p>
            </div>
              <div className='text-left my-4 md:my-0 col-span-1' >
                <img  src={Discover} alt="" />
                <h3 className='font-[GilroyBold]  ml-2 md:text-[1.5rem] text-[1.2rem] mt-2 text-teal-500'>Discover</h3>
                <p className='font-[GilroyMedium]  ml-2 md:text-[1.2rem] text-[.8rem] mt-1 text-black'>Browse our deals, deep dive into the investment  <br className='hidden lg:block' /> details and find your favorite opportunities</p>
            </div>
              <div className='text-left my-4 md:my-0 col-span-1' >
                <img  src={Stay} alt="" />
                <h3 className='font-[GilroyBold]  ml-2 md:text-[1.5rem] text-[1.2rem] mt-2 text-teal-500'>Stay tuned</h3>
                <p className='font-[GilroyMedium]  ml-2 md:text-[1.2rem] text-[.8rem] mt-1 text-black'>Get constant reports and updates, enjoying full <br className='hidden lg:block' /> transparency on deal progresses</p>
            </div>
              <div className='text-left my-4 md:my-0 col-span-1' >
                <img  src={Monitor} alt="" />
                <h3 className='font-[GilroyBold]  ml-2 md:text-[1.5rem] text-[1.2rem] mt-2 text-teal-500'>Monitor</h3>
                <p className='font-[GilroyMedium]  ml-2 md:text-[1.2rem] text-[.8rem] mt-1 text-black'>Explore your portfolio performance anytime, anywhere, <br className='hidden lg:block' /> always in your pocket</p>
            </div>
        </div>
    </div>
  )
}

export default Experience