import React from 'react'
import AssetClass from "../../asset/assetClass.webp"
const AssetClasses = () => {
  return (
    <div className='md:grid grid-cols-2 grid-flow-col border-gray-200 border-b-[1px] gap-12 items-center md:mx-10 lg:mx-24 py-12 mx-8 md:py-24' >
        <div>
            <h4 className='font-[GilroyMedium] text-teal-500 text-[1rem] lg:text-[1.2rem] ' >Asset classes</h4>
            <h5 className='font-[GilroyBold] hidden lg:block text-black text-[1.5rem] lg:text-[2rem] leading-[2.4rem]' >Real Estate projects at different <br /> stages,  across all asset classes</h5>
             <h5 className='font-[GilroyBold] block lg:hidden text-black text-[1.5rem] lg:text-[2rem] leading-[2.4rem]' >Real Estate projects at different stages,  across all asset classes</h5>
            <p className='font-[GilroyMedium] text-[.8rem] lg:text-[1.1rem] my-2 lg:leading-[1.9rem] ' >Our pipeline of selected off-market deals includes new <br className='hidden lg:block' /> constructions, refurbishment projects and income <br className='hidden lg:block'  /> generating properties, with a particular focus on high-end,<br className='hidden lg:block' /> luxury developments, which can prove resilient in <br /> turbulent times.</p>
            <p className='font-[GilroyMedium] text-[.8rem] lg:text-[1.1rem] my-2 lg:leading-[1.9rem] '>We consider Real Estate projects at different stages <br className='hidden lg:block' /> (Core+, Value Add and Opportunistic), across all asset <br className='hidden lg:block' /> classes, spanning from <span className='text-teal-500' >Residential</span>  to <span className='text-teal-500' >Hospitality,</span> also <br className='hidden lg:block' /> including <span className='text-teal-500' > Logistics, Commercial,</span> Office and Retail.</p>
        </div>
        <div className='flex items-center justify-end' >
            <img className='lg:w-[450px] mt-3 lg:mt-0' src={AssetClass} alt="" />
        </div>
    </div>
  )
}

export default AssetClasses