import React from 'react'
import DealImage from "../../asset/dealimage.png"
import FundImage from "../../asset/fundImage.png"
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { LuMoveRight } from "react-icons/lu";
import { urlFor } from '../../client';
const Offering = ({home}) => {
  return (
    <div className="xl:px-24 px-4 py-6 xl:py-16 bg-[#e8e8e8]">
        <div className='lg:grid lg:grid-cols-5  gap-12  grid-flow-col' >
            <div className='lg:col-span-2 my-4 lg:my-0 '  >
                <h4 className='font-[GilroyMedium] text-teal-500'>Our Offerings</h4>
                <h3 className='font-[GilroyBold] leading-8 lg:leading-10 text-[1.5rem] lg:text-[2rem]'  >Choose between single deals or diversified strategies</h3>
                <p className='font-[GilroyRegular] lg:text-[1rem] text-[.8rem] ' >Select individual real estate investments or choose a diversified portfolio of private, off-market real estate opportunities originated by our Real Estate professionals</p>
            </div>
            <div className='lg:col-span-3  gap-8 grid lg:grid-cols-2 grid-flow-row' >
            <div className='bg-white'  >
            <div className='relative' >
          {home?.offeringProp1?.image &&    <img src={urlFor(home?.offeringProp1?.image)} />}
              <h3 className='absolute' ></h3>
            </div>
            <div className='px-6 py-3' >
          <p className='text-[.8rem]  h-[80px] font-[GilroyMedium]'>{home?.offeringProp1?.content}</p>
          <h4 className='font-[GilroyBold] mt-3 flex items-center justify-start gap-6 text-[1.2rem]' >{home?.offeringProp1?.title} <LuMoveRight className='text-[2rem]' /> </h4>
            </div>
            </div>
            <div className='bg-white'  >
            <div className='relative' >
            {home?.offeringProp2?.image && <img src={urlFor(home?.offeringProp2?.image)} /> }
              <h3 className='absolute' ></h3>
            </div>
            <div className='px-6 py-3' >
          <p className='text-[.8rem] h-[80px] font-[GilroyMedium]' >{home?.offeringProp2?.content}</p>
          <h4 className='font-[GilroyBold] mt-3 flex items-center justify-start gap-6 text-[1.2rem]' >{home?.offeringProp2?.title}<LuMoveRight className='text-[2rem]' /> </h4>
            </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default Offering