import React from 'react'

const PropertyDetail = ({property}) => {
  return (
    <div className='lg:py-12 py-8 px-8 lg:px-36'>
        <h4 className='font-[GilroyBold] text-[2rem] lg:text-[2.5rem]' > <span className='text-[#FE6539]' >Property</span>  Details</h4>
        <div className='my-8' >
        {
          property?.propertyDetails?.map((item,index)=>(
            <p className='font-[GilroyMedium] text-[.9rem] lg:text-[1.2rem] lg:py-6 py-3 border-b-[1px] border-gray-300 ' >{index+1}. {item}</p>
          ))
        }
        
       
        </div>
    </div>
  )
}

export default PropertyDetail