import React from 'react'
import AssetClass from "../../asset/strategy.png"
const Investment = () => {
  return (
    <div className='lg:grid grid-cols-2 grid-flow-col border-gray-200 border-b-[1px] gap-12 items-center mx-8 py-12 lg:mx-24 lg:py-24' >
        <div>
            <h5 className='font-[GilroyBold] text-black lg:text-[3rem] text-[2rem] lg:leading-[3.3rem]' >Discover our newly <br /> launched investment strategy</h5>
            <p className='font-[GilroyMedium] text-[1.1rem] my-2 leading-[1.9rem] ' >Our incentives are fully aligned with our investors': REON leadership <br /> coinvests in every deal we propose to our investors</p>
            <button className='rounded-full font-[GilroyBold] text-[.9rem] mt-6 py-3 px-12 bg-black text-white font-[600]' >Invest Now</button>
        </div>
        <div className='flex items-center justify-end' >
            <img className='w-[450px] mt-4 lg:mt-0 ' src={AssetClass} alt="" />
        </div>
    </div>
  )
}

export default Investment