import React from 'react'
import PropertyLocationImage from "../../asset/propertyLocation.png"
import PortableText from 'react-portable-text'
import { urlFor } from '../../client'
const PropertyLocation = ({property}) => {
  return (
    <div className='lg:py-12 py-8 mx-8  lg:mx-36  '>
        <h4 className='font-[GilroyBold] text-[2rem] lg:text-[2.5rem]' >Property <span className='text-[#FE6539]' > Location</span></h4>
        <div className='lg:grid grid-cols-2 items-center justify-center gap-8 grid-flow-col' >
            <div>
                 <p className='font-[GilroyMedium] text-[.9rem] lg:text-[1.3rem]' >
           {property?.propertyLocation?.mainContent && <PortableText
      // Pass in block content straight from Sanity.io
      content={property?.propertyLocation?.mainContent}
      // Optionally override marks, decorators, blocks, etc. in a flat
      // structure without doing any gymnastics
      serializers={{
        h1: (props) => <h1 style={{"background":"linear-gradient(to right, #ee0979 0%, #ff6a00 100%)","-webkit-background-clip":"text","-webkit-text-fill-color":"transparent",fontFamily:"NHaasGroteskDSPro-75Bd",fontWeight:"bold",fontSize:"2rem", margin:"15px 0px",textAlign:"center"}} {...props} />,
        h3: (props) => <h3 style={{ color: "#3c3c3c",margin:"15px 0px",fontSize:"1.8rem",fontWeight:"600" }} {...props} />,
        li: ({ children }) => <li className="text-gray-700 text-[1.2rem] ml-6 list-disc">{children}</li>,
        normal: (props) => <normal style={{  }} {...props} />
      }}
    />}</p>
            </div>
             <div className='flex items-center justify-end' >
           {property?.propertyLocation && <img className='mt-6 lg:mt-0' src={urlFor(property?.propertyLocation?.mainImage)} alt="" />}
        </div>
        </div>
       
    </div>
  )
}

export default PropertyLocation