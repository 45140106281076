import sanityClient from "@sanity/client"
import imageUrlBuilder from "@sanity/image-url"

export const client = sanityClient({
    projectId:"vbm3a6lo",
    dataset:'production',
    apiVersion:'2022-03-07',
    userCdn:true,
    token:"skZab4d5TblhW68Pm5mvwI6sKNT3QdJwCb7HQt64uSi8yTiZIq7Nxigyl63FJp3aNblGeLe989EEbayz9VCjwWsx3I1Fv3ja7b1UTGxRbEv7WggVKoFF6o0mFML6eivE1Bsjm44roJOn08xrLdKLcrm2utZVhL3zSVhBOXpMvyKdojnSpQpl"

})

const builder = imageUrlBuilder(client);

export const urlFor = (source)=> builder.image(source);