import React, { useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
// Import Swiper styles
import "swiper/css";
import BelowBanner from "./BelowBanner"
import Prop1 from "../../asset/prop1.png"
import Prop2 from "../../asset/Prop2.png"

import { CgMediaLive } from "react-icons/cg";
import PropertyCard from './PropertyCard';
const BelowBannerCarousel = ({home}) => {
  const [activeTab, setactiveTab] = useState("Pre-Leased")
  const activeClass = "w-[180px] py-3 border-[2px] font-[GilroyMedium] shadow-lg shadow-teal-100 text-white border-teal-500 rounded-full text-[.7rem] lg:text-[.8rem] bg-teal-500"
  const inActiveClass = "w-[180px] py-3 border-[2px] text-teal-500 shadow-lg  hover:bg-gray-200 font-[GilroyMedium] border-teal-500 rounded-full text-[.7rem] lg:text-[.8rem]"
  return (
    <div className='xl:px-24 px-6 py-6 xl:py-16' > 
  
        <div className='lg:grid grid-cols-2 grid-flow-col lg:gap-16' >
          <div>
            <div className='flex lg:my-8 my-4 items-center justify-start gap-4 lg:gap-8' > 
              <button onClick={()=>setactiveTab("Pre-Leased")} className={`${activeTab==="Pre-Leased" ? activeClass : inActiveClass}`} >Pre-Leased Residential 
              </button>
              <button  onClick={()=>setactiveTab("Coliving")} className={`${activeTab==="Coliving" ? activeClass : inActiveClass}`}>Coliving Asset Fund</button>
            </div>
            <h4 className='lg:text-[3rem] text-[1.5rem] font-[GilroyBold] ' >Build your Real Estate Portfolio!</h4>
           
          </div>
    {  activeTab === "Pre-Leased" &&     <div className='lg:grid lg:mt-[100px] gap-8 grid-cols-1 xl:grid-cols-2 grid-flow-col' >
       {
        home?.preLeasedProperty?.map((property)=>(
          <PropertyCard property={property} />
        ))
       }
          </div>}
          {
            activeTab === "Coliving" &&     <div className=' flex items-center justify-center '>
              {home?.colivingProperty ? 
     (   home?.preLeasedProperty?.map((property)=>(
          <PropertyCard property={property} />
        ))):(
          <p className='lg:text-[1rem] text-[.8rem] text-center bg-teal-500 px-6 py-4 font-[GilroyMedium] flex items-center justify-center gap-3 shadow-lg shadow-teal-200 mt-3  text-white rounded-lg' > <CgMediaLive className='text-[1.5rem]' />Coming Soon</p>
        )
       }
             
              </div>
          }
        </div>
    </div>
  )
}

export default BelowBannerCarousel