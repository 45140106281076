import React from 'react'

import "./Fund.css"
const MainBanner = () => {
  return (
    <div  className='fundBanner mt-[-120px] lg:mt-[-130px]' >
        <div className='lg:pt-[220px] pt-[170px] px-8 lg:w-[900px] md:w-[600px] lg:px-24 flex-col flex items-start justify-start ' >
            <h4 className='lg:text-[3rem] text-[1.8rem] font-[GilroyBold] text-left text-white font-[600]' >Get instant diversification via Reon Debt Real Estate Fund</h4>
            <p className='text-white lg:text-[1rem] text-[.8rem] font-[GilroyMedium] font-[400] mt-3  ' >Discover the benefits of investing in a diversified portfolio of <br className='hidden lg:block' /> mezzanine and senior real estate positions</p>
            <button className='rounded-full font-[GilroyBold] text-[.9rem] mt-6 lg:mt-10 py-3 px-12 bg-white font-[600]' >Subscribe to get Deal Updates</button>
        </div>
    </div>
  )
}

export default MainBanner