import React from 'react'
import PortableText from 'react-portable-text'

const BelowBanner = ({property}) => {
  return (
    <div className='py-12 mx-8  lg:mx-36 border-b-[1px] border-gray-300 ' >
        {property?.detailedContent && <p>
         <PortableText
      // Pass in block content straight from Sanity.io
      content={property?.detailedContent}
      // Optionally override marks, decorators, blocks, etc. in a flat
      // structure without doing any gymnastics
      serializers={{
        h1: (props) => <h1 style={{"background":"linear-gradient(to right, #ee0979 0%, #ff6a00 100%)","-webkit-background-clip":"text","-webkit-text-fill-color":"transparent",fontFamily:"NHaasGroteskDSPro-75Bd",fontWeight:"bold",fontSize:"2rem", margin:"15px 0px",textAlign:"center"}} {...props} />,
        h3: (props) => <h3 style={{ color: "#3c3c3c",margin:"15px 0px",fontSize:"1.8rem",fontWeight:"600" }} {...props} />,
        li: ({ children }) => <li className="special-list-item">{children}</li>,
        normal: (props) => <normal style={{ color: "#808080",margin:"15px 0px",fontSize:"1rem" }} {...props} />
      }}
    />
        </p>}
        
    </div>
  )
}

export default BelowBanner