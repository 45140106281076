import React from 'react'
import TicketIcon from "../../asset/ticketIcon.png"
import TickIcon from "../../asset/tickIcon.png"
import RefreshIcon from "../../asset/refresh.png"
import KeyIcon from "../../asset/key.png"
import WalletIcon from "../../asset/wallet.png"
import SearchIcon from "../../asset/search.png"
import RildLogo from "../../asset/logoMain.png"
import { RiMoneyRupeeCircleLine } from "react-icons/ri";
import { HiOutlineLibrary } from "react-icons/hi";
import { MdRemoveRedEye } from "react-icons/md";
import { FaWallet } from "react-icons/fa6";
import { FaRegThumbsUp } from "react-icons/fa6";
const WhyReon = () => {
  return (
    <div className="xl:px-24 px-6 py-6 xl:py-16 bg-[#11193d]">
        <div className='' >
            <img className='w-[180px]' src={RildLogo} />
            <p className=' text-[.7rem] lg:text-[1.5rem] text-white font-[GilroyMedium] md:w-[500px] lg:w-[800px] font-[500] ' >We make investing in commercial real estate easy with low ticket sizes and personalized services online and offline.</p>
        </div>
        <div className='mt-12 lg:mt-12 gap-8 grid items-center justify-center grid-cols-1 xl:grid-cols-3 2xl:grid-cols-3 grid-flow-row ' >
            <div className='bg-gray-300  transform transition duration-500  hover:scale-110  hover:bg-white hover:border-[1px] hover:border-[#a1a1a1] lg:h-full flex items-center justify-center flex-col lg:py-8 py-6 px-6 lg:px-4 rounded-lg' >
                <FaWallet className='text-[#11193d] text-[3.5rem]' />
                <p className='mt-4 font-[600] text-[1rem] text-center lg:text-[1rem] font-[GilroyBold]' >Monthly Income</p>
                <p className=' font-[500] text-[.7rem] text-center lg:text-[0.8rem] font-[GilroyMedium]'>Monthly Returns from the point of Investment</p>
            </div>
            <div className='bg-gray-300   transform transition duration-500  hover:scale-110  hover:bg-white lg:h-full flex items-center justify-center flex-col lg:py-8 py-6 px-6 lg:px-4 rounded-lg' >
               <HiOutlineLibrary className='text-[#11193d] text-[3.5rem]' />
                <p className='mt-4 font-[600] text-[1rem] text-center lg:text-[1rem] font-[GilroyBold]' >Institutional Opportunities</p>
                <p className=' font-[500] text-[.7rem] text-center lg:text-[0.8rem] font-[GilroyMedium]'>Less than 1% of the Opportunities pass the stringent WiseX Selection Criteria</p>
            </div>
            <div className='bg-gray-300   transform transition duration-500  hover:scale-110  hover:bg-white lg:h-full flex items-center justify-center flex-col lg:py-8 py-6 px-6 lg:px-4 rounded-lg' >
            <MdRemoveRedEye className='text-[#11193d] text-[3.5rem]' />
                <p className='mt-4 font-[600] text-[1rem] text-center lg:text-[1rem] font-[GilroyBold]' >Transparency</p>
                <p className=' font-[500] text-[.7rem] text-center lg:text-[0.8rem] font-[GilroyMedium]'>Transparent investment process, access to detailed Reporting and complete Disclosures</p>
            </div>
            <div className='bg-gray-300   transform transition duration-500  hover:scale-110  hover:bg-white lg:h-full flex items-center justify-center flex-col lg:py-8 py-6 px-6 lg:px-4 rounded-lg' >
            <FaRegThumbsUp className='text-[#11193d] text-[3.5rem]' />
                <p className='mt-4 font-[600] text-[1rem] text-center lg:text-[1rem] font-[GilroyBold]' >Convenience</p>
                <p className=' font-[500] text-[.7rem] text-center lg:text-[0.8rem] font-[GilroyMedium]'>End to end Investment Lifecycle support with a Dedicated Investment Manager</p>
            </div>
            <div className='bg-gray-300   transform transition duration-500  hover:scale-110  hover:bg-white lg:h-full flex items-center justify-center flex-col lg:py-8 py-6 px-6 lg:px-4 rounded-lg' >
            <RiMoneyRupeeCircleLine className='text-[#11193d] text-[3.5rem]' />
                <p className='mt-4 font-[600] text-[1rem] text-center lg:text-[1rem] font-[GilroyBold]' >Liquidity</p>
                <p className=' font-[500] text-[.7rem] text-center lg:text-[0.8rem] font-[GilroyMedium]'>Tech enabled platform provides improved liquidity with multiple exit options</p>
            </div>
          
        </div>
    </div>
  )
}

export default WhyReon